



















































import { Component, Vue } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';

import { WorkOrderListModule } from '@/store/index';
import { DispatchModule } from '@/store/index';
import ExportFilters from '../ExportFilters/ExportFilters.vue';
import { DispatchType } from '../../models/dispatch.model';
import { DispatchService } from '@/shared/services/dispatch/dispatch.service';
import { MasterDataService } from '../../../../shared/services/mater-data/master-data.service';
import { WorkOrderService } from '@/shared/services/wo/wo-import.service';
import { ToastHelper } from '@/utils/toast.util';
import WoBaseModal from '@/pages/Main/components/WoBaseModal.vue';
import {
  BS_DATEPICKER_WITH_TIME,
  convertDateFieldToAPIFormat,
  convertAPIToFormat,
  convertAPIToViewFullFormat
} from '@/utils/date.util';
import Loading from '@/shared/components/Loading.vue';
import {
  ReturnTableHeaders,
  ReturnCategoriesHeaders
} from '../models/tables/return';
import TmsTable from '@/shared/components/table/TmsTable.vue';

import {
  ValidationService,
  stringCommonRule
} from '@/shared/services/validation/validation.service';
import { JobsService } from '@/shared/services/jobs/jobs.service';
import {
  DispatchScheduleLocation,
  LocationType
} from '@/shared/models/woSchedule.model';
import { JobPayments } from '@/shared/models/driverIdModal.model';
import DriverIdModal from '@/shared/components/modal/DriverIdModal.vue';
import DriverInputWrapper from '@/shared/components/form/DriverInputWrapper.vue';
import { isClickedOnInput } from '@/shared/models/wo.model';
import { getRowSchedules } from '../../utils/get-row-schedules';
import {
  getScheduleLocationErrors,
  getScheduleOrderErrors
} from '@/shared/services/wo/schedule-validation';
import { PermissionsService } from '@/shared/services/permission/permission.service';
import { AdminConfService } from '@/shared/services/admin-conf/admin-conf.service';

@Component({
  components: {
    Fragment,
    WoBaseModal,
    ExportFilters,
    Loading,
    DriverIdModal,
    DriverInputWrapper,
    TmsTable
  }
})
export default class Return extends Vue {
  convertAPIToFormat = convertAPIToFormat;
  convertAPIToViewFullFormat = convertAPIToViewFullFormat;
  dispatchModule = DispatchModule;
  masterDataService = MasterDataService;

  LocationType = LocationType;
  woModalId = 'wo-base-modal';

  activeItem = null;
  activeItemOrderNumber = null;

  jobPayments: JobPayments = null;

  disabled = false;

  tabIndex = 0;
  contextMenuOptions = [
    {
      name: this.$i18n.t('contextMenu.detailInfo'),
      slug: 'detail-info-modal',
      index: 0
    },
    {
      name: this.$i18n.t('contextMenu.billingInfo'),
      slug: 'billing-info-modal',
      index: 1
    },
    {
      name: this.$i18n.t('contextMenu.document'),
      slug: 'document-modal',
      index: 2
    },
    {
      name: this.$i18n.t('contextMenu.memo'),
      slug: 'memo-modal',
      index: 3
    },
    {
      name: this.$i18n.t('contextMenu.tracking'),
      slug: 'tracking-modal',
      index: 4
    }
  ];

  tableKey = true;
  loading = false;
  currentPage = 1;
  routeName = 'export';
  scheduleClickedIndex: number = null;
  driverClickedName: string = null;
  expReturns = [];
  selectedWo = {};
  workOrderListModule = WorkOrderListModule;
  categoryTableHeaders = ReturnCategoriesHeaders;
  columnHeaders = ReturnTableHeaders(AdminConfService.chassisPool);
  isShown = {
    eq: true,
    info: true,
    book: true,
    loc: true,
    geo: true,
    sched: true
  };
  options = {
    pool: [
      { value: 'POOL', text: 'POOL' },
      { value: 'NGL', text: 'NGL' },
      { value: 'AIM', text: 'AIM' },
      { value: 'APL', text: 'APL' },
      { value: 'CS', text: 'CHINASHIPPING' },
      { value: 'CSNA', text: 'CSNA' },
      { value: 'DCLI', text: 'DCLI' },
      { value: 'EG', text: 'EVERGREEN' },
      { value: 'FLEXI', text: 'FLEXI' },
      { value: 'TNWA', text: 'TNWA' },
      { value: 'POP', text: 'POP' },
      { value: 'HD', text: 'HD' },
      { value: 'LABP', text: 'LABP' },
      { value: 'MTSN', text: 'MATSON' },
      { value: 'TAXZ', text: 'TAXZ' },
      { value: 'WCCP', text: 'WCCP' },
      { value: 'YM', text: 'YANGMING' },
      { value: 'GCCP', text: 'GCCP' },
      { value: 'GACP', text: 'GACP' },
      { value: 'KLINE', text: 'K-LINE' },
      { value: 'MOL', text: 'MOL' },
      { value: 'TPSP', text: 'TPSP' },
      { value: 'SEA', text: 'SEALAND' }
    ],
    liveDrp: [
      { value: 'SELECT', text: 'Select' },
      { value: 'DROP', text: 'Drop' },
      { value: 'LIVE', text: 'Live' }
    ]
  };
  stringsToValidate = [
    'chassisNumber',
    'chassisPool',
    'pickupDriver',
    'returnDriver'
  ];

  validationRules = {
    chassisNumber: [{ name: 'regular', value: '^[a-zA-Z]{4}[0-9]{6}$' }]
  };
  validationService = new ValidationService(this.validationRules);

  constructor() {
    super();
  }

  created() {
    this.disabled = PermissionsService.can(
      'DSP / MAIN / BC DETAIL',
      'EXP',
      'View'
    );
  }

  openDetailModal(selectedData, index, event?) {
    if (event && isClickedOnInput(event)) {
      return;
    }
    this.selectedWo = selectedData;
    this.tabIndex = index;
    this.$bvModal.show('wo-base-modal');
  }

  rowRightClicked(item: any, index, event) {
    event.preventDefault();
    const contextMenu: any = this.$refs.contextMenu;
    contextMenu.showMenu(event, item);
  }

  onContextMenuOptionSelect(event: {
    item: any;
    option: { name: string; slug: string; index: number };
  }) {
    this.openDetailModal(event.item, event.option.index);
  }

  async onPaginationChange() {
    await this.dispatchModule.search({
      dispatchType: DispatchType[this.routeName.substr(0, 3).toUpperCase()],
      category: this.$route.name.replace(`${this.routeName}-`, '')
    });
  }

  validateItem(item: any, itemName: string) {
    return this.validationService.checkItemValidity(item, itemName);
  }

  validationClass(item: any, itemName: string) {
    return this.validationService.validationClass(item, itemName);
  }

  assign(driverId) {
    this.dispatchModule.dispatches[this.scheduleClickedIndex][
      this.driverClickedName
    ] = driverId;
  }

  openDriverSearchModal(index, propToChange) {
    this.scheduleClickedIndex = index;
    this.driverClickedName = propToChange;
    this.$bvModal.show('DriversSearchModal');
  }

  async loadTable() {
    this.workOrderListModule.setWOType('IMP-REG');
    await this.workOrderListModule.search({ type: 'IMP-REG' });
    this.expReturns = JSON.parse(
      JSON.stringify(this.workOrderListModule.workOrders)
    );
  }

  get editableData() {
    return this.dispatchModule.dispatches;
  }

  get isLastPage(): boolean {
    return !this.dispatchModule.meta.lastKey;
  }

  onAction({ key, data }: { key: string; data: any }) {
    switch (key) {
      case 'save':
        this.checkJobPayments(data);
        break;
      case 'complete':
        this.complete(data.orderNumber);
        break;
      case 'chassisPool':
      case 'dropLive':
        break;
      default:
        this.openDetailModal(data, 0);
    }
  }

  rowClass(item) {
    if (!item) return;

    if ((item.cfFile && item.cfsFile) || item.cfFile) {
      return 'light-red-row';
    } else if (item.cfsFile) {
      return 'green-row';
    }
  }

  changeIsShownHandler(param) {
    this.isShown[param] = !this.isShown[param];
  }

  showDetails(item) {
    this.selectedWo = item;
    this.$bvModal.show('wo-base-modal');
  }

  async checkJobPayments(item) {
    this.activeItem = item;

    const editedSchedules = getRowSchedules(item);

    const datesOrderErrors = getScheduleOrderErrors(editedSchedules);
    if (datesOrderErrors.length) {
      ToastHelper.show('Error', datesOrderErrors[0], 5000, 'danger');
      return;
    }

    this.save({
      jobPayments: item.jobPayments,
      schedules: editedSchedules
    });
  }

  async save({ jobPayments, schedules }) {
    !this.activeItem.chassisNumber
      ? (this.activeItem.chassisNumber = null)
      : '';

    if (this.validationService.checkAllItemsValidity(this.activeItem)) {
      this.loading = true;

      try {
        await DispatchService.saveRequest(
          DispatchType[this.routeName.substr(0, 3).toUpperCase()],
          this.$route.name.replace(`${this.routeName}-`, ''),
          this.activeItem.orderNumber,
          {
            chassisNumber: this.activeItem.chassisNumber,
            chassisPool: this.activeItem.chassisPool,
            overHeight: this.activeItem.overHeight,
            remarks: this.activeItem.remarks,
            jobPayments,
            schedules
          }
        );
        ToastHelper.show(
          `${this.routeName}/${this.$route.name.replace(
            `${this.routeName}-`,
            ''
          )}`,
          'Save successful',
          5000,
          'success'
        );
      } catch (err) {
        ToastHelper.show(
          `${this.routeName}/${this.$route.name.replace(
            `${this.routeName}-`,
            ''
          )}`,
          err.message,
          5000,
          'danger'
        );
      }

      this.loading = false;
      this.tableKey = !this.tableKey;
    } else {
      console.log('error!');
    }
  }

  async complete(id) {
    this.loading = true;

    await WorkOrderService.completeOrder(
      DispatchType.EXP,
      id,
      'dispatch',
      false
    );

    this.loading = false;
  }

  async search() {
    try {
      await DispatchModule.search({
        dispatchType: DispatchType[this.routeName.substr(0, 3).toUpperCase()],
        category: this.$route.name.replace(`${this.routeName}-`, '')
      });
    } catch (err) {
      ToastHelper.show(
        `${this.routeName}/${this.$route.name.replace(
          `${this.routeName}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    }
  }
}
